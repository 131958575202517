<template>

  <div class="row">
    <div class="col-12 card">
      <div class="row justify-content-space-between top-24">
        <h4 class="col-3 card-title">Добавить отчёт</h4>
        <div class="col-9 align-self-end right">
          <router-link to="/reports"><button class="col-3 btn-secondary">Отменить</button></router-link>
          <button class="col-3 btn-primary"><span class="icon-Plus"></span>Сохранить</button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 top-24">
          <div class="text-field">
            <label class="text-field__label" for="search">Имя отчёта</label>
            <div class="text-field__icon">
              <input class="text-field__input" type="search" name="search" id="search" placeholder="Введите имя" value="">
              <div class="text-field__message">Будет отображаться в общем списке отчётов</div>
            </div>
          </div>
        </div>
        <div class="col-12 top-24">
              <label class="text-field__label" for="search">Тип отчёта</label>
              <div class="text-field__icon">
                <!-- <input class="text-field__input" type="search" name="search" id="search" placeholder="Введите имя" value=""> -->
                <div class="select">
                  <select id="standard-select" @change="onChange($event)">
                    <option value="table">Таблица</option>
                    <option value="chart">График</option>
                  </select>
                  <span class="focus"></span>
                </div>
              </div>
        </div>
        <table-view v-if="this.type === 'table'"></table-view>
        <chart-view v-if="this.type === 'chart'"></chart-view>
        <div class="col-12">
          <div class="top-24">
            <input type="checkbox" class="custom-checkbox" id="archive" name="happy" value="yes">
            <label for="archive">Отправить в архив</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableView from "@/components/Analytics/ReportsCreate/TableView.vue";
import ChartView from '@/components/Analytics/ReportsCreate/ChartView.vue';

export default {
  components: { TableView, ChartView },
  data() {
    return {
      type: "table"
    }
  },
  methods:{
    onChange:function(event){
      this.type = event.target.value;
    }
  }
};
</script>
