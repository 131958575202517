<template>
        <div class="col-12 top-24">
          <div class="row group-field-bd">
            <div class="col-12">
              <label class="text-field__label" for="search">Заголовок столбца</label>
              <div class="text-field__icon">
                <input class="text-field__input" type="search" name="search" id="search" placeholder="Введите название столбца" value="">
                </div>
            </div>
            <div class="col-6">
              <label class="text-field__label" for="search">Название обработчика</label>
              <div class="text-field__icon">
                <!-- <input class="text-field__input" type="search" name="search" id="search" placeholder="Введите имя" value=""> -->
                <div class="select">
                  <select id="standard-select">
                    <option value="Option 1">Тест 1</option>
                    <option value="Option 2">Тест 2</option>
                    <option value="Option 3">Тест 3</option>
                  </select>
                  <span class="focus"></span>
                </div>
              </div>
            </div>
            <div class="col-6">
              <label class="text-field__label" for="search">Тип поля</label>
              <div class="text-field__icon">
                <!-- <input class="text-field__input" type="search" name="search" id="search" placeholder="Введите имя" value=""> -->
                <div class="select">
                  <select id="standard-select">
                    <option value="Option 1">Тест 1</option>
                    <option value="Option 2">Тест 2</option>
                    <option value="Option 3">Тест 3</option>
                  </select>
                  <span class="focus"></span>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="text-field__message top-24">или</div>
              <label class="text-field__label top-24" for="search">Пользовательский скрипт</label>
              <div class="text-field__icon">
                <input class="text-field__input" type="search" name="search" id="search" placeholder="Введите Java Script" value="">
              </div>
            </div>
            <button class="col-2 btn-secondary top-24"><span class="icon-Plus"></span>Добавить</button>
          </div>
        </div>
  
</template>

<script>

</script>

<style scoped>

</style>